import { PLACE_COUNTRY } from '@/lib/constants';
import { countryNames } from '@/lib/constants/country-names';
import { places } from '@/lib/constants/places';
import { LocationInterface, OptionItem } from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { formatPortIndentifers } from '@/utils/format/port-indentifers';

/**
 * Looks up country and adds its data to the Place
 * @param place Location Object
 * @returns Location Object with Country Data added
 */
export const addCountryToPlace = (place?: LocationInterface | null): LocationInterface | null => {
  if (!place || !place.countryCode) return null;
  const country = getCountryByCode(place.countryCode);

  return {
    ...place,
    country: {
      name: country?.name || '',
      canonical: country?.placeId || '',
    },
  };
};

/**
 * Finds a country by country code
 * @param countryCode Country Code to lookup
 * @returns Found Place
 */
export const getCountryByCode = (countryCode: string): LocationInterface | undefined => {
  return places.find((p) => p.countryCode === countryCode && p.type === PLACE_COUNTRY);
};

/**
 * Find single place by id
 * @param id ID to lookup Place by
 * @returns Found Place
 */
export const findPlaceById = (id: string): LocationInterface | null => {
  return addCountryToPlace(places.find((c) => c.placeId === id));
};

/**
 * Determines if a place is a country Type
 * @param place Location Object to check against
 * @returns True/false if is a Country
 */
export const isPlaceCountry = (place: LocationInterface): boolean => {
  return place?.type === PLACE_COUNTRY;
};

/**
 * Gets Country Name from LocationInterface
 * @param location Location Object
 * @returns Name of Location Object
 */
export const getPlaceCountryName = (place: LocationInterface): string | undefined => {
  return isPlaceCountry(place) ? place.name : place.country?.name;
};

/**
 * Get Place and Country as concated string
 * @param place Location Object
 * @returns Place, Country string (i.e Melbourne, Australia)
 */
export const getPlaceAndCountryString = (place: LocationInterface): string => {
  return isPlaceCountry(place) ? place.name : `${place.name}, ${place.country?.name}`;
};

/**
 * Ensures a place has a name and shortname and they are formatted correctly
 */
export const formatPlaceNames = (place: LocationInterface): LocationInterface => {
  return {
    ...place,
    name: getPlaceAndCountryString(place),
    shortName: place.name,
  };
};

/**
 * transform Places into OptionItems
 */
export const transformPlacesToOptions = (placesItems: PlaceInterface[]): OptionItem[] => {
  return placesItems
    .map((place) => {
      if (place.identifier) {
        return { value: formatPortIndentifers([place.identifier]), label: place.name || place.shortName };
      }
      return null;
    })
    .filter(Boolean) as OptionItem[];
};

/**
 * Convert Country Object into Option List
 */
export const countriesSelectBoxOptions = Object.keys(countryNames).map((countryKey) => {
  return { value: countryKey, label: countryNames[countryKey] };
});

/**
 * Check if string matches any places in list exactly
 */
export const placeHasExactMatch = (placeName: string, placeList: PlaceInterface[]): boolean => {
  return !!placeList.find((place) => place.name === placeName);
};

/**
 * Returns true if the string matches the pattern of a placeKey
 * i.e melbourne-au, london-uk, etc
 */
export const placeIsPossiblePlaceKey = (string: string): boolean => {
  const pattern: RegExp = /.+-\w{2}$/;
  return pattern.test(string);
};
