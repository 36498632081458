/**
 * Takes two objects and merges differences from the second object onto the first object.
 * ex.
 * Object1 = Default Settings Object
 * Object2 = User Settings Object
 * Object2 gets applied on top of Object1, but maintains any properties that exist in Object1 that aren't in Object2
 * and removes any properties that exist on Object2 that aren't defined in Object1 (i.e stale/old settings)
 */
export const intersectObjectsLeft = <A extends Record<string, unknown> | object, B extends Record<string, unknown>>(
  masterObject: A,
  objectToIntersect: B,
): A => {
  // Removes Items from objectToMerge that aren't in the MasterObject
  const intersectingData = Object.keys(masterObject).reduce((accum, key) => {
    const property = objectToIntersect[key];
    return property ? { ...accum, [key]: property } : accum;
  }, {});
  // Start with master and merges in remaining intersections
  return { ...masterObject, ...intersectingData };
};
