import React from 'react';

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  as?: React.ElementType;
}

const VisuallyHidden = ({ children, as = 'span', ...props }: Props) =>
  React.createElement(as, { tabIndex: -1, className: 'sr-only', ...props }, children);

export { VisuallyHidden };
