import { isEmpty } from 'lodash';

import { ShipmentVehicle } from '@/lib/models/shipment/types';
import { DEFAULT_SCHEDULE_SEARCH_PARAMS } from '@/state/standalone-schedules';
import {
  AdvancedSearchParams,
  AutocompletePlaceTypes,
  LocationInterface,
  PortsUrlQueryParams,
  RouteApiQueryParams,
  ScheduleSearchParamsInterface,
  ServicesApiParams,
  SpotRatesSearchParams,
  StandaloneScheduleSearchParams,
} from '@/types';
import { SegmentModeType } from '@/types/api-types';
import { OrgsPublicTableInterface } from '@/types/database-types';
import { Fluent } from '@/utils/environment';
import { formatDateRangeForUrl } from '@/utils/format/date-time';
import { createQueryString } from '@/utils/helpers';
import { getLocationPortNodeId } from '@/utils/helpers/get-location-port-id';
import { formatPreferredIdentifier } from '@/utils/helpers/get-preferred-identifier';

import { getLocationApiParam } from '../get-location-api-param';

const { FLUENT_API_URL } = Fluent.env;

/**
 * Creates a url for the carriers search API
 */
export const createCarrierSearchUrl = (searchString: string, type?: SegmentModeType): string => {
  return `${FLUENT_API_URL}/carriers?q=${searchString}${type ? `&type=${type}` : ''}`;
};

/**
 * Creates a url for the places search API
 */
export const createPlacesSearchUrl = (searchString: string, placeTypes?: AutocompletePlaceTypes): string => {
  return `${FLUENT_API_URL}/places?q=${searchString}${placeTypes?.length ? `&placeTypes=${placeTypes.join(',')}` : ''}`;
};

/**
 * Creates a url for the place details API
 * @param placeId - place to lookup
 * @param placeName - name fallback
 */
export const createPlaceDetailsSearchUrl = (placeId: string, placeName: string): string => {
  return `${FLUENT_API_URL}/place-details?placeId=${placeId}&string=${placeName}`;
};
/**
 * Creates a url for the Qayaq Route Search API endpoint
 */
export const createRouteSearchParams = (
  originLocation: string | LocationInterface,
  destinationLocation: string | LocationInterface,
  features?: string,
): RouteApiQueryParams => {
  // If a nodeId or Node Location (Seaport or Airport) is provided, just send the identifier
  const oId = getLocationPortNodeId(originLocation);
  const dId = getLocationPortNodeId(destinationLocation);

  // Else handle sending a Location Param Object
  const originQuery = oId ? { oId } : { oLocation: getLocationApiParam(originLocation) };
  const destinationQuery = dId ? { dId } : { dLocation: getLocationApiParam(destinationLocation) };

  return {
    ...originQuery,
    ...destinationQuery,
    features: features || '',
  };
};

/**
 * Returns a fully formed Route Query String
 */
export const createRouteSearchUrl = (
  origin?: LocationInterface | null,
  destination?: LocationInterface | null,
  advancedSearchParams: AdvancedSearchParams | null = null,
) => {
  if (!origin || !destination) return null;
  const query = createQueryString({
    o: origin.name,
    d: destination.name,
    ...advancedSearchParams,
  });

  return `${getRouteSearchUrl()}${query}`;
};

/**
 * Returns a fully formed Route Query String
 */
export const getRouteSearchUrl = () => `${FLUENT_API_URL}/routes`;

/**
 * Returns a fully Schedules Url String
 */
export const createStandaloneScheduleUrl = (
  params: Partial<StandaloneScheduleSearchParams>,
  isEnabled = true,
): string | null => {
  if (!isEnabled) return null;

  const { origin, destination, mode, dateFrom, dateTo, carrier, aircraftType, serviceType, features, lineSearch } = {
    ...DEFAULT_SCHEDULE_SEARCH_PARAMS,
    ...params,
  };
  if (!origin || !destination || !mode) return null;

  // Build query string of extra params to send
  const queryString = createQueryString({
    pol: formatPreferredIdentifier(origin.identifier),
    pod: formatPreferredIdentifier(destination.identifier),
    mode,
    dateFrom,
    dateTo,
    carrier,
    aircraftType,
    serviceType,
    features: features || '',
    lineSearch: lineSearch || '',
  });

  return `${getStandaloneSchedulesUrl()}${queryString}`;
};

/**
 * Returns a fully formed Schedule Query String
 * @param params
 * @param shouldUseLimited - will use the "limited" api endpoint to fetch a restricted
 * subset of schedules
 */
export const createScheduleSearchUrl = (params: ScheduleSearchParamsInterface, shouldUseLimited = false): string => {
  const { fromCode, toCode, mode, lineQuery, dateRange, arriveBy, aircraft, departHour, arriveHour } = params;
  // Build query string of extra params to send
  const queryString = createQueryString({
    o: fromCode,
    d: toCode,
    mode,
    arriveBy: arriveBy || false,
    dateRange: dateRange ? formatDateRangeForUrl({ dateRange, departHour, arriveHour }) : false,
    lines: lineQuery ? JSON.stringify(lineQuery) : false,
    ...(aircraft || {}),
  });

  return `${getSchedulesUrl(shouldUseLimited)}${queryString}`;
};

export const createSpotRatesSearchUrl = (params: Partial<SpotRatesSearchParams>) => {
  const { pol, pod, mode, dateFrom, dateTo } = {
    ...params,
  };
  if (!pol || !pod || !mode) return null;

  const queryString = createQueryString({
    pol,
    pod,
    mode,
    dateFrom: dateFrom || '',
    dateTo: dateTo || '',
  });

  return `${getSpotRatesUrl()}${queryString}`;
};

export const getUsageUrl = () => `${FLUENT_API_URL}/usage`;

export const getSubscriptionInvoicesUrl = () => `${FLUENT_API_URL}/subscription/invoices`;

export const getSubscriptionUrl = () => `${FLUENT_API_URL}/subscription`;

export const getPlansUrl = () => `${FLUENT_API_URL}/subscription/plans`;

export const getFlightServicesUrl = () => `${FLUENT_API_URL}/services`;

export const getPortsUrl = (params?: PortsUrlQueryParams) =>
  !params ? `${FLUENT_API_URL}/ports` : `${FLUENT_API_URL}/ports${createQueryString(params as Record<string, string>)}`;

export const getSchedulesUrl = (shouldUseLimited?: boolean) =>
  `${FLUENT_API_URL}/schedules${shouldUseLimited ? '/limited' : ''}`;

export const getStandaloneSchedulesUrl = () => `${FLUENT_API_URL}/schedules/search`;

export const getOrgRootUrl = () => {
  return `${FLUENT_API_URL}/orgs`;
};

export const getOrgUsersUrl = (orgId: OrgsPublicTableInterface['id']) => {
  if (!orgId) {
    return null;
  }

  return `${FLUENT_API_URL}/orgs/${orgId}/users`;
};

export const getOrgsUsersInvitesUrl = (orgId: OrgsPublicTableInterface['id']) => {
  if (!orgId) {
    return null;
  }

  return `${FLUENT_API_URL}/orgs/${orgId}/users/invite`;
};

export const getOrgUrl = (orgId: OrgsPublicTableInterface['id']) => {
  if (!orgId) {
    return null;
  }

  return `${FLUENT_API_URL}/orgs/${orgId}`;
};

export const getInvitesUrl = () => {
  return `${FLUENT_API_URL}/orgs/invites`;
};

export const getInviteOrgUserUrl = (orgId: string) => {
  return `${FLUENT_API_URL}/orgs/${orgId}/users/invite`;
};

export const getQuotesUrl = (page: number, perPage: number, statuses: string[]) => {
  return `${FLUENT_API_URL}/quotes?page=${page}&perPage=${perPage}&statuses=${statuses}`;
};

export const getOrgsUrl = (page: number, perPage: number, subscriptionStatus: string) => {
  return `${FLUENT_API_URL}/orgs?page=${page}&perPage=${perPage}&subscriptionStatus=${subscriptionStatus}`;
};

export const getSpotRatesUrl = () => `${FLUENT_API_URL}/rates/live`;

export const getTotalUsersCountUrl = () => {
  return `${FLUENT_API_URL}/users/count`;
};

export const getRevenueTotalsUrl = () => {
  return `${FLUENT_API_URL}/subscription/revenue`;
};

export const getDisruptionAlertsUrl = () => {
  return `${FLUENT_API_URL}/disruption-alerts`;
};

export const getFlightServicesUrlWithParams = (params: ServicesApiParams) => {
  const routesString = JSON.stringify(params.routes);
  const query = createQueryString({ ...params, routes: routesString });

  return `${getFlightServicesUrl()}${query}`;
};

export const getAllCarriersUrl = () => `${FLUENT_API_URL}/carriers/all`;

export const getUldsUrl = () => {
  return `${FLUENT_API_URL}/uld`;
};

export const getSearchHistoryUrl = (perPage = 5, page = 0) => {
  return `${FLUENT_API_URL}/usage/history?perPage=${perPage}&page=${page}`;
};

export const getPathsApiUrl = (ids: string[]) => {
  return `${FLUENT_API_URL}/paths?ids=${ids.join(',')}`;
};

export const getMailingListUrl = () => {
  return `${FLUENT_API_URL}/mailing-list`;
};

export const getContactFormEnquiryUrl = () => {
  return `${FLUENT_API_URL}/contact-form`;
};

export const getShipmentsUrl = () => {
  return `${FLUENT_API_URL}/shipments`;
};

export const getShipmentFiltersUrl = () => {
  return `${getShipmentsUrl()}/filters`;
};

export const getShipmentsUpdateUrl = (shipmentId: string) => {
  return `${getShipmentsUrl()}/${shipmentId}/update`;
};

export const getShipmentTagsUrl = (shipmentId: string) => {
  return `${getShipmentsUrl()}/${shipmentId}/tags`;
};

export const getShipmentsCountsUrl = () => {
  return `${getShipmentsUrl()}/count`;
};

export const getUpdateUserUrl = () => `${FLUENT_API_URL}/auth/update-user`;

export const getSearchUsersUrl = (email?: string, userId?: string) => {
  if (!email && !userId) return null;

  const query = createQueryString({
    email: email || '',
    userId: userId || '',
  });

  return `${FLUENT_API_URL}/users/search${query}`;
};

export const getInternalAuthUrl = () => `http://localhost:6006/api/auth`;

export const getVesselLocationsUrl = (vehicles: ShipmentVehicle[]) => {
  if (isEmpty(vehicles)) {
    return null;
  }
  const vehicleKeys = vehicles.map((vehicle) => vehicle.name || '');
  return `${getShipmentsUrl()}/vessel-locations?vehicles=${vehicleKeys.join(',')}`;
};

export const getSavedItemsUrl = (id?: string) => {
  const base = `${FLUENT_API_URL}/saved-items`;
  if (id) {
    return `${base}/${id}`;
  }

  return base;
};

export const getSharedItemsUrl = (id?: string) => {
  const base = `${FLUENT_API_URL}/shared-items`;
  if (id) {
    return `${base}/${id}`;
  }

  return base;
};

export const getSharedItemsFindUrl = () => {
  return `${getSharedItemsUrl()}/find`;
};

export const getTagsUrl = (id?: string) => {
  const base = `${FLUENT_API_URL}/tags`;
  if (id) {
    return `${base}/${id}`;
  }

  return base;
};
