import { flatten, isEmpty, last } from 'lodash';

import { CANAL_TRANSIT, MODE_TRANSFER, TRANSFER_STOP } from '@/lib/constants';
import {
  LocationInterface,
  RouteBlocksInterface,
  RouteScheduleSegmentInterface,
  RouteSearchQueryParams,
  RouteSegmentInterface,
  SearchQueryInterface,
} from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { formatDuration } from '@/utils/format';
import { getPreferredIdentifier, removeEmptyValues } from '@/utils/helpers';
import { QUERY_PARAM } from '@/utils/helpers/api-urls/constants';
import { cargoToQueryParams } from '@/utils/helpers/search-filters';
import { getPlaceAndCountryString } from '@/utils/places';

/**
 * Get Route Primary Segments
 * Gets the Primary Segments from an array of Blocks in a flat array
 */
const getRoutePrimarySegments = (blocks: RouteBlocksInterface[]): RouteSegmentInterface[] => {
  const segments = blocks.map((block) => block.options[0]?.segments);
  return flatten(segments);
};

/**
 * Get route transfer names
 * Returns array of transfer names
 */
const getRouteTransferNames = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(
  segments: T[],
  codesOnly = false,
): string[] => {
  if (segments.length === 1) return [];
  // Remove last item from array
  const transferSegments = getRouteTransfers(segments);

  // Map through and return transfer locations from segments
  // Returns `transfer Name (Port ID)` OR just PORT ID
  const transfers = transferSegments.map((segment) => {
    const place = segment.from;
    const id = place ? getPreferredIdentifier(place.identifiers) : '';
    // If only codes, early out and return just code
    if (codesOnly && id) return id.value;
    // Else handle full name response
    if (!place?.name) return '';
    return `${place?.name} ${id ? `(${id.value})` : ''}`;
  });
  // Filter out anything with no name and return
  return transfers.filter((transfer) => transfer);
};

/**
 * Get Route transfer time
 * Returns sum of Route Transfer durations
 */
const getRouteTimeInTransfer = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(
  segments: T[],
): string => {
  const transferTime = segments.reduce((accum, segment) => {
    if (segment.mode === MODE_TRANSFER) return accum + segment.duration;
    return accum;
  }, 0);
  return formatDuration(transferTime);
};

/**
 * Get Route transit time
 * Returns sum of Route Transit durations
 */
const getRouteTimeInTransit = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(
  segments: T[],
): string => {
  const transferTime = segments.reduce((accum, segment) => {
    if (segment.mode !== MODE_TRANSFER) return accum + segment.duration;
    return accum;
  }, 0);
  return formatDuration(transferTime);
};

/**
 * Get Route stops
 * Returns Stops segments (that aren't transfers)
 */
const getRouteStopovers = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(segments: T[]): T[] => {
  return segments.filter((s) => s.mode === MODE_TRANSFER && s.transferType === TRANSFER_STOP);
};

/**
 * Get Route transfers
 * Returns Transfer segment (that aren't stops)
 */
const getRouteTransfers = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(segments: T[]): T[] => {
  return segments.filter(
    (s) => s.mode === MODE_TRANSFER && s.transferType !== TRANSFER_STOP && s.transferType !== CANAL_TRANSIT,
  );
};

/**
 * Get Route transfer length
 * Returns count of Transfers in Route
 */
const getRouteTransferLen = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(segments: T[]): number =>
  getRouteTransfers(segments).length;

/**
 * Calculates a Total Duration from all Segments provided
 * @param segments RouteSegmentInterface[]
 * @returns number
 */
const getTotalDuration = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(segments: T[]): number => {
  return segments.reduce((total, segment) => {
    return total + segment.duration;
  }, 0);
};

/**
 * Groups Segments based on Transfers
 * @param segments RouteSegmentInterface[]
 * @returns RouteSegmentInterface[]
 */
const groupSegmentsAtTransfers = <T extends RouteSegmentInterface | RouteScheduleSegmentInterface>(
  segments: T[],
): T[][] => {
  const groups: T[][] = [[]];
  segments.forEach((segment) => {
    const lastGroup = last(groups) || [];
    // if is Transfer, and is not Stop or Canal Transit
    // Push transfer into groups, add a new empty one for next segment
    if (
      segment.mode === MODE_TRANSFER &&
      segment.transferType !== TRANSFER_STOP &&
      segment.transferType !== CANAL_TRANSIT
    ) {
      groups.push([segment], []);
    } else {
      // Else Add segment to last chunk
      lastGroup.push(segment);
    }
  });
  return groups;
};

const getLocationValue = (location?: PlaceInterface | LocationInterface | string) => {
  if (!location) return '';
  if (typeof location === 'string') return location;
  if ('country' in location) return getPlaceAndCountryString(location);
  if ('name' in location) return location.name;
  return '';
};

export const locationsToSearchQueryParams = ({
  origin,
  destination,
}: {
  origin?: PlaceInterface | LocationInterface | string;
  destination?: PlaceInterface | LocationInterface | string;
}): RouteSearchQueryParams => {
  const originName = getLocationValue(origin);
  const destinationName = getLocationValue(destination);

  return {
    [QUERY_PARAM.ORIGIN]: originName,
    [QUERY_PARAM.DESTINATION]: destinationName,
  };
};

const searchStateToQueryParams = ({
  origin,
  destination,
  advancedSearchParams,
}: {
  origin?: PlaceInterface;
  destination?: PlaceInterface;
  advancedSearchParams: SearchQueryInterface;
}) => {
  const searchParams = locationsToSearchQueryParams({ origin, destination });

  const parsedAdvancedSearchParams = {
    ...removeEmptyValues(advancedSearchParams),
    ...(advancedSearchParams.cargo ? { cargo: cargoToQueryParams(advancedSearchParams.cargo) } : {}),
  };

  if (!isEmpty(parsedAdvancedSearchParams)) {
    searchParams[QUERY_PARAM.ADVANCED_SEARCH] = JSON.stringify(parsedAdvancedSearchParams);
  }

  return searchParams;
};

export {
  getRoutePrimarySegments,
  getRouteStopovers,
  getRouteTimeInTransfer,
  getRouteTimeInTransit,
  getRouteTransferLen,
  getRouteTransferNames,
  getRouteTransfers,
  getTotalDuration,
  groupSegmentsAtTransfers,
  searchStateToQueryParams,
};
