import { forEach, isEmpty, uniq, uniqBy } from 'lodash';

import { ID_CANONICAL, ID_IATA, ID_ICAO, ID_SCAC, MODE_AIR, MODE_ROAD, MODE_WATER } from '@/lib/constants';
import {
  CarrierSearchInterface,
  OptionItem,
  RouteCarrierInfoInterface,
  RouteScheduleSegmentInterface,
  ScheduleInterface,
} from '@/types';
import {
  FrequencyApiInterface,
  IdentifierApiInterface,
  RouteCarrierApiV2Interface,
  RouteCarriersApiV2Interface,
  ScheduleRouteSegmentApiInterface,
} from '@/types/api-types';

/**
 * Returns list of unique carrierIds
 */
export const getUniqueCarrierIds = (carriers: RouteCarrierInfoInterface[]): string[] => {
  const carrierIds = carriers.map((carrier) => carrier.carrierId);
  return uniq(carrierIds);
};

/**
 * Returns count of unique carriers
 */
export const getUniqueCarrierCount = (carriers: RouteCarrierInfoInterface[]): number => {
  return getUniqueCarrierIds(carriers).length;
};

/**
 * Gets Carrier Ids
 * Returns an array of Carrier Ids from a an array of Segments
 */
export const getCarrierIdsFromScheduleSegments = (
  segments: RouteScheduleSegmentInterface[] | ScheduleRouteSegmentApiInterface[],
): string[] => {
  const ids = segments.map((segment) => segment.carrierId);
  const filteredIds = ids.filter((id) => id !== 'EmptyCarrier');
  return uniq(filteredIds);
};

/**
 * Get Carrier by Id
 * Finds a carrier by its id in a list of Carriers
 */
export const getCarrierById = (
  carrierId: string,
  carriers: RouteCarriersApiV2Interface,
): RouteCarrierApiV2Interface => {
  return carriers[carrierId];
};

/**
 * Gets Carrier names
 * Returns an array of Carrier Names from a Carriers Array
 */
export const getCarrierNames = (carriers: RouteCarrierApiV2Interface[]): string[] => {
  return carriers.map((carrier) => getCarrierName(carrier));
};

/**
 * Gets Carrier name
 * Returns a Carrier Name
 */
export const getCarrierName = (carrier: RouteCarrierApiV2Interface): string => {
  const shortName = carrier?.names?.short?.length ? carrier.names.short[0] : '';
  const longName = carrier?.names?.long?.length ? carrier.names.long[0] : '';
  return shortName || longName;
};

/**
 * Gets Carrier long name if available
 * Returns a Carrier Name
 */
export const getCarrierLongName = (carrier: RouteCarrierApiV2Interface): string => {
  const shortName = carrier?.names?.short?.length ? carrier.names.short[0] : '';
  const longName = carrier?.names?.long?.length ? carrier.names.long[0] : '';
  return longName || shortName;
};

/**
 * Get merged Carriers
 * Takes a segment and merges any duplicated carriers
 */
export const getMergedCarriers = (carrierInfo: RouteCarrierInfoInterface[]): RouteCarrierInfoInterface[] => {
  const carriers = carrierInfo.reduce(
    (accum, carrier) => {
      const groups = { ...accum };
      const carrierGroup = groups[carrier.carrierId];

      if (carrierGroup) {
        // Merge into existing data
        const mergedCarriers = mergeTwoCarriers(carrierGroup, carrier);
        groups[carrier.carrierId] = mergedCarriers;
      } else {
        groups[carrier.carrierId] = carrier;
      }
      return groups;
    },
    {} as Record<string, RouteCarrierInfoInterface | null>,
  );

  return Object.values(carriers).filter((carrier): carrier is RouteCarrierInfoInterface => Boolean(carrier));
};

const mergeTwoCarriers = (
  carrierA: RouteCarrierInfoInterface,
  carrierB: RouteCarrierInfoInterface,
): RouteCarrierInfoInterface | null => {
  // Ensure the carrier Ids are the same
  if (carrierA.carrierId !== carrierB.carrierId) return null;

  return {
    carrierId: carrierA.carrierId,
    frequency: combineCarrierFrequency([carrierA, carrierB]),
    schedule: combineCarrierSchedules([carrierA, carrierB]),
    vehicleIds: uniq([...carrierA.vehicleIds, ...carrierB.vehicleIds]),
    vehicles: uniqBy([...carrierA.vehicles, ...carrierB.vehicles], 'vehicleId'),
    attributes: {
      isFreighter: carrierA.attributes.isFreighter || carrierB.attributes.isFreighter,
      isWidebody: carrierA.attributes.isWidebody || carrierB.attributes.isWidebody,
      isNarrowbody: carrierA.attributes.isNarrowbody || carrierB.attributes.isNarrowbody,
    },
  };
};

/**
 * Combine Carrier Schedules
 * Combines Carrier schedules for all carrierInfo objs provided
 */
export const combineCarrierSchedules = (carrierInfo: RouteCarrierInfoInterface[]): ScheduleInterface => {
  const defaultSchedules: ScheduleInterface = { frequency: {}, type: 'day' };

  // Process carrierInfo array
  if (!isEmpty(carrierInfo)) {
    carrierInfo.forEach((carrier) => {
      const { frequency, type } = carrier.schedule;
      // Update Type
      defaultSchedules.type = type;
      // Loop through each day and add to existing values
      forEach(frequency, (value, key) => {
        // Add to day if it already exists, else create
        defaultSchedules.frequency[key] = defaultSchedules.frequency[key]
          ? defaultSchedules.frequency[key] + value
          : value;
      });
    });
  }

  return defaultSchedules;
};

/**
 * Combine Carrier Frequency
 * Combines two or more carrier frequencies
 */
export const combineCarrierFrequency = (carrierInfo: RouteCarrierInfoInterface[]): FrequencyApiInterface => {
  const defaultFrequency = {
    type: 'unknown',
    value: 0,
    perHour: 0,
  } as FrequencyApiInterface;

  if (!carrierInfo) return defaultFrequency;
  // Return early if only 1 carrier
  if (carrierInfo.length === 1) return carrierInfo[0].frequency;

  return carrierInfo.reduce((accum, carrier) => {
    const carrierFreq = carrier.frequency;
    return {
      ...accum,
      perHour: accum.perHour + carrierFreq.perHour,
    };
  }, defaultFrequency);
};

/**
 * Transform CarrierSearch into OptionItems
 */
export const transformCarrierToOptions = (carrierItems: CarrierSearchInterface[]): OptionItem[] => {
  return carrierItems.map((item) => {
    return { value: item.id, label: item.name };
  });
};

/**
 * Determines if a carrier is road, air, water or rail
 */
export const determineCarrierType = (carrierIdentifiers: IdentifierApiInterface[]) => {
  const keys = carrierIdentifiers.map((id) => id.type);

  if (keys.includes(ID_ICAO) || keys.includes(ID_IATA)) return MODE_AIR;
  if (keys.includes(ID_SCAC) || keys.includes(ID_CANONICAL)) return MODE_WATER;
  return MODE_ROAD;
};
